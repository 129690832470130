import HeroSection from "./components/HeroSection"



function App() {
  return (
    <div>
      <HeroSection/>


    </div>
  );
}

export default App;
