// firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyC6FAyNfHIoA16Z50D0ZV-M3ilokOkvXhw",
  authDomain: "firstcofounder.firebaseapp.com",
  projectId: "firstcofounder",
  storageBucket: "firstcofounder.appspot.com",
  messagingSenderId: "829931400554",
  appId: "1:829931400554:web:2f4df2a18b083f618c4913",
  measurementId: "G-J0S68NYLY2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

export { db };
